import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom"; // Import useLocation for route checks
import styles from '../styles/Header.module.css';

export default function Header() {
  const [showMe, setShowMe] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);

  const location = useLocation(); // Use useLocation to get the current route

  function toggle() {
    setShowMe(!showMe);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      // You can adjust the threshold for when the header should start shrinking
      setIsShrunk(scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Check if the current route is the homepage ("/")
  const isHomePage = location.pathname === '/';

  return (
    <header className={styles.header}>
      {/* Conditionally render the logo based on the current route */}
      {isHomePage ? null : (
        <div className={`${styles.logo} ${isShrunk ? styles.shrunk : ''}`}>
          <Link to="/">
            <img
              itemProp="image"
              src="/Cyberlab-Australia.svg"
              alt="Cyberlab Security Australia"
              width={390}
              height={220}
              loading="eager"
            />
          </Link>
          <div className={styles.cyberlab}>Cyberlab</div>
          <p className={styles.cyberlabsub}>Australia</p>
        </div>
      )}

      <nav className={showMe ? styles.show : styles.hide}>
        <ul className={styles.menu}>
          <li>
            <Link to="/about" title="Cyberlab Australia">Cyberlab</Link>
          </li>
          <li>
            <Link to="/security" title="Security Solution">Security Solution</Link>
          </li>
          <li>
            <Link to="/alerts" title="Security Alerts">Security Alerts</Link>
          </li>
          <li>
            <Link to="/contact" title="Cyberlab Contact">Connect with Us</Link>
          </li>
        </ul>
      </nav>

      <button
        className={styles.hamb}
        onClick={toggle}
        id="cyberlabmenu"
        title="Cyberlab menu"
      >
        {showMe ? (
          <span className={styles.close}></span>
        ) : (
          <span className={styles.hambline}></span>
        )}
      </button>
    </header>
  );
}
