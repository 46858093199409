import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from './styles/Home.module.css'
import Layout from './components/Layout';
import { news as data } from './data/alerts'; // Assuming you are using static data for now.
import NewsItem from "./components/NewsItem";

export default function Home() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    // Fetch the news data when the component mounts (client-side)
    setNews(data.slice(0, 4)); // You can replace this with a dynamic fetch if needed
  }, []);

  return (
    <div className={styles.container}>
      <Layout>
        <main className={styles.main}></main>

        <div className={styles.homenews}>
          <h2><Link to="/hub" title="Cyber Security Alerts">Security Alerts</Link></h2>
          <h3>Stay vigilant and informed with our Security Alerts</h3>
          <p className={styles.hometxt}>Updates on emerging threats and protective measures</p>
        </div>

        {/* Add a check to ensure news is defined */}
        {news.length === 0 ? (
          <h3>No News</h3>
        ) : (
          news.map((item) => <NewsItem key={item.id} news={item} />)
        )}
      </Layout>
    </div>
  );
}
