import React from "react"; 
import styles from './styles/Home.module.css';
import Layout from './components/Layout';

export default function Contact() {
  return (
    <div className={styles.container}>
      <Layout
        title="Cyberlab Security Australia"
        description="Cyberlab Security Australia Uncover the power of proactive defense strategies and stay ahead of evolving cyber threats"
        canonical="https://cyberlabau.com/contact"
        keywords="Cyberlab, Cyber Security"
      >
        <main className={styles.main}>
          <h1>Connect with Cyberlab</h1>
          <h2>For Emergency after hours please phone</h2>
          <p>
            Ensuring you have immediate assistance during emergencies. When you call us, you connect with a dedicated
            support team ready to address your needs promptly and effectively. Trust us to be there for you anytime,
            providing reliable solutions whenever you require urgent assistance.
          </p>
          <div className={styles.grid}>
            <div className={styles.contactme}>
              <img src="/www.svg" alt="cyberlab" width={80} height={80} />
              <h3>Location</h3>
              <p>Sydney, Australia</p>
            </div>
            <div className={styles.contactme}>
              <img src="/mobile.svg" alt="D24 Media Linkedin" width={80} height={80} />
              <h3>Phone</h3>
              <p>0433 883 288</p>
            </div>
            <div className={styles.contactme}>
              <img src="/email.svg" alt="D24 Digital Media Instagram" width={80} height={80} />
              <h3>Email</h3>
              <p>info@cyberlabau.com</p>
            </div>
          </div>
        </main>
      </Layout>
    </div>
  );
}
