import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { news as data } from '../data/alerts'; // Import your data directly
import NewsItem from "../components/NewsItem";
import styles from "../styles/News.module.css";

export default function News() {
  const [news, setNews] = useState([]);

  // Fetch data when the component mounts
  useEffect(() => {
    setNews(data); // Load news data into state
  }, []);

  return (
    <div className={styles.container}>
      <Layout 
        canonical="https://cyberlabau.com/alerts"
        title="Cyber Security Alerts and System Update Information" 
        description="Cyber Security Alerts and System Update Information"
        keywords="Network Defence, Security Patches and Updates, Cyber Security Update"
      >
        <main className={styles.main}>
          <h1>Latest Security Alerts</h1>
          <h2>Expertise in Cyber Threats Prevention</h2>
          <p>
            <strong>Cyberlab</strong> is a cybersecurity company offering a range of solutions to help organizations protect their digital assets and sensitive information from cyber threats. Here are some popular <i>cybersecurity</i> solutions:
          </p>

          <div className={styles.grid}>
            {news.length === 0 && <h3>No News</h3>}
            {news.map((item) => (
              <div key={item.id} className={styles.newsItem}>
                <Link to={`/alerts/${item.slug}`} title={`Read more about ${item.name}`}>
                  <NewsItem news={item} />
                </Link>
              </div>
            ))}
          </div>
        </main>
      </Layout>
    </div>
  );
}
