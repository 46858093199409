import React from "react";
import { Link } from "react-router-dom"; // Ensure proper import
import styles from './styles/Home.module.css';
import Layout from './components/Layout';

export default function About() {
  return (
    <div className={styles.container}>
      <Layout
        canonical="https://cyberlabau.com/about"
        title="Cyberlab Security Australia"
        description="CyberLab for comprehensive security solutions tailored to your unique needs. Elevate your cybersecurity posture today and embrace a safer digital future."
        keywords="cyberlab, cybersecurity, Cyberlab Security Australia"
      >
        <main className={styles.main}>
          <h1>Cyberlab Australia - Securing Your Interconnected World</h1>
          <h2>Your Cyber Security shield against evolving cyber threats</h2>
          <p>
            Welcome to <strong>CyberLab</strong> Security located in Australia, your trusted partner in navigating the
            dynamic landscape of cybersecurity in Australia. Competitive edge in the ever-evolving digital space
            requires embracing inventiveness. We have been at the forefront of witnessing the evolution of the internet
            and the ever-growing spectrum of cyber threats. With a profound commitment to excellence, our journey has
            been marked by invaluable experiences and a relentless dedication to mitigating emerging issues in the
            digital realm.
          </p>
          <h3>Our Evolution: A Chronicle of Cyber Resilience</h3>
          <p>
            In the fast-paced world of technology, we recognised early on the critical need for robust cybersecurity
            measures. Our inception is fueled by a vision to not just adapt to the changing <strong>cybersecurity</strong>{" "}
            landscape but to lead and innovate. Over the years, we have honed our expertise, evolving alongside the
            internet's transformative journey.
          </p>

          <h3>Experience and Mitigation: Navigating the Cyberspace</h3>
          <p>
            Our experience extends beyond merely witnessing the evolution; it encapsulates hands-on encounters with a
            myriad of cyber threats. From traditional malware to sophisticated ransomware attacks, we have stood
            resilient against the ever-shifting tactics employed by cyber adversaries. Our team of cybersecurity experts
            brings a wealth of experience in identifying vulnerabilities, conducting thorough risk assessments, and
            implementing effective mitigation strategies.
          </p>

          <h2>Why Choose CyberLab Security Australia?</h2>
          <h3>Local Expertise, Global Standards</h3>
          <p>
            As a homegrown cybersecurity firm, we understand the unique nuances of the Australian market. Our solutions
            are not only tailored to meet international standards like Essential Eight and NIST Cyber Security
            Frameworks, but are intricately crafted to address the specific challenges faced by businesses and
            individuals in Australia.
          </p>
          <h3>Proactive Approach</h3>
          <p>
            In the realm of cybersecurity, being reactive is not an option. We pride ourselves on our proactive approach
            to identifying and neutralising potential threats before they escalate. Our predictive analytics and threat
            intelligence keep you one step ahead in the cybersecurity game.
          </p>
          <h3>Tailored Solutions</h3>
          <p>
            At CyberLab Security Australia, we acknowledge that every entity has its own set of challenges. Our
            solutions are not one-size-fits-all; they are customized to suit the unique needs of our clients. Whether
            you are a small business or a large enterprise, we have the expertise to fortify your digital infrastructure
          </p>
          <h3>Comprehensive Services</h3>
          <p>
            From vulnerability assessments and penetration testing to incident response and compliance consulting, we
            offer a comprehensive suite of services. This ensures that your organization is fortified on all fronts,
            leaving no room for cyber threats to compromise your security.
          </p>
          <h3>Community Engagement</h3>
          <p>
            Beyond our role as <i>cybersecurity experts</i>, we actively engage with the local community to raise
            awareness about cybersecurity best practices. We believe that an informed community is a more secure
            community, and we are committed to contributing to the overall digital resilience of Australia.
          </p>

          <h2>Trusted Cyber Security Partner</h2>
          <p>
            Understanding the unique intricacies of the local Australian market is ingrained in our approach. From
            compliance requirements to industry-specific challenges, we comprehend what it takes to secure your digital
            assets in the Australian context. Our solutions go beyond generic cybersecurity practices; they are designed
            to align seamlessly with the regulatory framework and business landscape Down Under.
          </p>
          <h2>Cyber Landscape</h2>
          <p>
            At CyberLab Security Australia, we don't just provide cybersecurity solutions. We offer peace of mind. We
            are committed to securing the digital future for organizations and businesses Australia-wide. Choose us as
            your cybersecurity partner, and together, let's fortify the digital frontier. Your security is our priority.
          </p>
          <p>
            Check out{" "}
            <Link to="/security" title="Cyber Security Solution Provider">
              Cyberlab Australia
            </Link>{" "}
            to see the quality of our work and extensive knowledge in this digital world.
          </p>
        </main>
      </Layout>
    </div>
  );
}
