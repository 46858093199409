import React from "react";
import { Link } from "react-router-dom"; // Ensure Link is properly used in the app
import styles from './styles/Home.module.css';
import Layout from './components/Layout';

export default function Security() {
  return (
    <div className={styles.container}>
      <Layout
        canonical="https://cyberlabau.com/security"
        title="Cyberlab Cybersecurity Solutions"
        description="Explore the cutting-edge world of cybersecurity with CyberLab - your premier destination for advanced cybersecurity solutions."
        keywords="cyberlab, cybersecurity solution"
      >
        <main className={styles.main}>
          <h1>Cybersecurity Solutions</h1>
          <h2>Expertise in Cyber Threats Prevention</h2>
          <p>
            <strong>Cyberlab</strong> is a cybersecurity company offering a range of services to help organizations
            protect their digital assets and sensitive information from cyber threats. Specialized in a full range of
            <i> cybersecurity solutions</i>.
          </p>
          <div className={styles.grid}>
            <Link to="/security">
              <div className={styles.card}>
                <img
                  src="/network-defence.svg"
                  width={80}
                  height={80}
                  alt="Vulnerability Assessment and Penetration Testing"
                  title="Vulnerability Assessment and Penetration Testing"
                />
                <h3>Vulnerability Assessment and Penetration Testing (VAPT)</h3>
                <p>Identifying and addressing vulnerabilities in networks, systems, and applications through simulated attacks.</p>
              </div>
            </Link>

            <Link to="/security">
              <div className={styles.card}>
                <img
                  src="/managed-security-services.svg"
                  width={80}
                  height={80}
                  alt="Managed Security Services (MSS)"
                  title="Managed Security Services (MSS)"
                />
                <h3>Managed Security Services (MSS)</h3>
                <p>
                  Continuous monitoring, management, and response to security incidents on behalf of clients. This may
                  include intrusion detection, firewall management, and log analysis.
                </p>
              </div>
            </Link>

            <Link to="/security">
              <div className={styles.card}>
                <img
                  src="/digital-forensic-incident-report.svg"
                  width={80}
                  height={80}
                  alt="Incident Response and Forensics"
                  title="Incident Response and Forensics"
                />
                <h3>Incident Response and Forensics</h3>
                <p>
                  Helping organizations respond to and recover from security incidents. This involves investigating and
                  analyzing breaches to understand their origin and impact.
                </p>
              </div>
            </Link>

            {/* Repeat similar updates for other items */}
          </div>
        </main>
      </Layout>
    </div>
  );
}
