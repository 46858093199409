import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./home";
import NewsDetails from "./alerts/[slug]";
import Alerts from "./alerts/index";
import About from "./about";
import Contact from "./contact";
import Security from "./security";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/security" element={<Security />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/alerts/:slug" element={<NewsDetails />} /> {/* Corrected the path here */}
      </Routes>
    </Router>
  );
}

export default App;
