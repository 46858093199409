import { useNavigate, useParams } from "react-router-dom"; // Removed unused Link
import { useEffect, useState } from "react";
import styles from '../styles/News.module.css';
import Layout from "../components/Layout";
import { news as data } from "../data/alerts"; // Import the data from your local source

const NewsDetails = () => {
  const { slug } = useParams(); // Get the 'slug' from the URL
  const navigate = useNavigate();
  const [currentPost, setCurrentPost] = useState(null);
  const [previous, setPrevious] = useState(null);
  const [next, setNext] = useState(null);

  useEffect(() => {
    console.log("Current slug:", slug);
    console.log("Available slugs in data:", data.map(item => item.slug));

    // Find the current post based on the slug
    const current = data.find((item) => item.slug === slug);
    if (current) {
      console.log("Current post found:", current);
      setCurrentPost(current);

      // Find previous and next posts based on the ID (assuming ID is a sequential number)
      const prev = data.find(item => item.id === current.id - 1) || null;
      const nxt = data.find(item => item.id === current.id + 1) || null;

      console.log("Previous post:", prev);
      console.log("Next post:", nxt);

      setPrevious(prev);
      setNext(nxt);
    } else {
      console.error(`No post found for slug: ${slug}`);
      navigate("/alerts"); // Redirect to alerts page if no matching slug is found
    }
  }, [slug, navigate]); // Effect runs when the slug changes

  if (!currentPost) {
    return <div>Loading...</div>; // Add a loading state while the post is being fetched
  }

  return (
    <Layout 
      title={currentPost.name} 
      description={currentPost.description} 
      keywords={currentPost.keywords} 
      canonical={currentPost.canonical}
    >
      <div className={styles.news}>
        <div className={styles.description}>
          <h1>{currentPost.name}</h1>
          <span className={styles.date}>
            {currentPost.date} {currentPost.time}
          </span>
        </div>
        {/* Uncomment this block if you want to display an image */}
        {/* {currentPost.image && (
          <div className={styles.image}>
            <img src={currentPost.image} alt={currentPost.name} style={{ width: '100%', height: 'auto' }} />
          </div>
        )} */}
        <p dangerouslySetInnerHTML={{ __html: currentPost.detail }} />

        <div className={styles.pagination}>
          <button onClick={() => previous && navigate(`/alerts/${previous.slug}`)} disabled={!previous}>
            Previous
          </button>
          <button onClick={() => navigate("/alerts/")}>Top</button>
          <button onClick={() => next && navigate(`/alerts/${next.slug}`)} disabled={!next}>
            Next
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default NewsDetails;
