import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import styles from "../styles/Splash.module.css";

export default function Splash() {
  return (
    <div className={styles.slide}>
      <span className={styles.slideimg}>
        <img
          itemProp="image"
          src="/Cyberlab-Australia.svg"
          alt="Cyberlab Security Australia"
          width={860}
          height={560}
          loading="eager"
        />
      </span>
      <span className={styles.center}>
        <h1>Cyberlab</h1>
      </span>
      <span className={styles.center}>
        <h2>Security Australia</h2>
      </span>

      <div className={styles.center}>
        <h3>Safeguard Your Digital Realm</h3>
      </div>
      <div className={styles.center}>
        <p>
          The Imperative of Cybersecurity. Protect your data, privacy, and assets in an interconnected world with robust
          cybersecurity measures, your shield against evolving online threats. Find out how we can help you.
        </p>
      </div>
      <div className={styles.centerbutton}>
        <Link to="/about" title="Cyberlab Australia">
          <button>About us</button>
        </Link>
        <Link to="/security" title="Cyberlab Security Solution">
          <button>Our Solutions</button>
        </Link>
      </div>
    </div>
  );
}
