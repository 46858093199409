import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import styles from "../styles/Splash.module.css";

export default function Framework() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the threshold based on your design
    };

    // Initial check on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageSrc = isMobile
    ? "/cyber-security-framework-nist-mobile.svg"
    : "/cyber-security-framework-nist.svg";
  const imageWidth = isMobile ? 460 : 1600;
  const imageHeight = isMobile ? 830 : 860;

  return (
    <div className={styles.frame}>
      <h2 className={styles.txtcenter}>Globally Adopted Cyber Security Standard</h2>
      <h3>Embrace a structured approach to cybersecurity with Industry-leading principles</h3>
      <p className={styles.frametxt}>
        Applying framework designed to facilitate organisations in effectively communicating cybersecurity risks to management and providing straightforward mitigation strategies for prevention
      </p>
      <p className={styles.centerimg}>
        <img
          src={imageSrc}
          width={imageWidth}
          height={imageHeight}
          alt="cyber security framework"
          title="cyber security framework"
        />
      </p>
      <div className={styles.centerbutton}>
        <Link to="/security" title="Cyberlab Security Solution">
          <button>Our Solutions</button>
        </Link>
      </div>
    </div>
  );
}
