import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import styles from "../styles/Footer.module.css";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footlinks}>
        <ul className={styles.clearfix}>
          <li>
            <Link to="/about" title="Cyberlab Australia">Cyberlab</Link>
          </li>
          <li>
            <Link to="/security" title="Security Solution">Security Solution</Link>
          </li>
          <li>
            <Link to="/alerts" title="Security Alerts">Security Alerts</Link>
          </li>
          <li>
            <Link to="/privacy" title="Cyberlab Privacy Policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/contact" title="Cyberlab Contact">Connect with Us</Link>
          </li>
        </ul>
      </div>
      <p className={styles.copy}>
        All Rights Reserved. <i>Cyberlab Security Australia</i> © 2024 
      </p>
      <p>
        Designed by <a href="https://d24.me" title="D24 Media">D24 Media</a>.
      </p>
    </footer>
  );
}
